import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import * as Sentry from "@sentry/nextjs";
import { SessionProvider } from "next-auth/react";
import Head from "next/head";
import wrapper from '../store/configureStore';
import ZendeskChatWidget from '../components/ZendeskChatWidget';
import { useLoadScript } from '@react-google-maps/api';
import { selectUser } from "../modules/users";
import { useSelector } from "react-redux";
import '../styles/styles.scss';
import { SpeedInsights } from '@vercel/speed-insights/next';

Sentry.init({
  dsn: 'https://97ba21e15c474e20f5cb7a0404332aed@o4505801582641152.ingest.sentry.io/4505801590439936',
  tracesSampleRate: 1.0,
  autoSessionTracking: true,
  replaysSessionSampleRate: parseFloat(
    process.env.NEXT_PUBLIC_SENTRY_REPLAYS_SESSION_SAMPLE_RATE ?? "1.0"
  ),
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT ?? "local",
  release: "si-app@" + process.env.NEXT_PUBLIC_APP_VERSION,
});

const libraries = ['places', 'marker', 'geometry', 'drawing'];

const PageApp = ({ Component, ...rest }) => {
  const { store, props } = wrapper.useWrappedStore(rest);
  const user = useSelector(selectUser);
  const userRole = user ? user.role : null;

  useEffect(() => {
    Sentry.setUser({ id: user ? user.id : "unidentified-user" });
    Sentry.setTag("user.role", userRole);
  }, [userRole]);

  useLoadScript({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    libraries,
    mapId: process.env.NEXT_PUBLIC_GOOGLE_MAP_ID
  });

  return (
    <Provider store={store}>
      <SessionProvider session={props.pageProps.session}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5, minimal-ui" />
          <meta name="robots" content="noindex, nofollow, noarchive, nocache, nosnippet, noimageindex" />
          <meta name="googlebot" content="noindex, nofollow" />
          <meta name="google" content="notranslate" />
          <meta name="googlebot-news" content="noindex, nofollow" />
          <meta name="googlebot-image" content="noindex, nofollow" />
          <meta name="googlebot-video" content="noindex, nofollow" />
          <meta name="slurp" content="noindex, nofollow" />
          <meta name="msnbot" content="noindex, nofollow" />
          <meta name="bingbot" content="noindex, nofollow" />
          <meta name="yandex" content="none" />
          <meta name="baiduspider" content="noindex, nofollow" />
        </Head>
        <Component {...props.pageProps} />
        <ZendeskChatWidget />
        <SpeedInsights />
      </SessionProvider>
    </Provider>
  );
};

export default wrapper.withRedux(PageApp);
